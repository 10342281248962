import { CopyProperties } from '../decorator/copy-properties.decorator';
import { Constant } from '../constant';
@CopyProperties()
export class UserProfile {
    public id: number;
    public code: string;
    public name: string;
    public shortName: string;
    public stockTradingCode: string;
    public avatar: string;
    public address1: string;
    public address2: string;
    public taxCode: string;
    public email: string;
    public email2: string;
    public userType: string;
    public issuerType: any;
    public status: string;
    public passport: string;
    public country: any;
    public countryId: string;
    public idType: any;
    public idTypeId: string;
    public province: any;
    public provinceId: string;
    public bankAccounts: any[];
    public bankAccount: any;
    public balance: string;
    public gender: number;
    public birthDate: string;
    public passportDateOfIssue: string;
    public passportDateOfIssueModel: any;
    public passportPlaceOfIssue: any;
    public businessCode: string;
    public contactPerson: string;
    public organizationType: string;
    public authorizedPerson: string;
    public phone: string;
    public secondContactInfo: any;
    public pendingProfileCounter: any;
    public phonePostal: string;
    public phoneCountryCode: string;
    public profilePicture: string;
    public website: string;
    public aboutUs: string;
    public photos: any;
    public showPhoneForInvestor: boolean;
    public isPersonalInvestor: boolean;
    public followingIssuers: any[];
    public followingBondIssuers: any[];
    public documents: any[];
    public additionalDocuments: any[];
    public userSocials: any[];
    public hasActiveProfile: boolean;
    public isSetRefCode: boolean;
    public isZaloOtp: boolean;
    public isFirst: boolean;
    public isKyc: boolean;
    public refCode: string;
    public datePassport: any;
    public profileStep: number;
    public referralCode: string;
    public totalDayInUse: number;
    public totalValidRefUser: number;
    constructor(
        params?: any
    ) {
        this.id = undefined;
        this.code = '';
        this.name = '';
        this.shortName = '';
        this.stockTradingCode = '';
        this.address1 = '';
        this.address2 = '';
        this.email = '';
        this.email2 = '';
        this.userType = '';
        this.idTypeId = '';
        this.issuerType = '';
        this.documents = [];
        this.passport = '';
        this.refCode = '';
        this.idType = null;
        this.secondContactInfo = null;
        this.showPhoneForInvestor = false;
        this.status = '';
        this.aboutUs =  '';
        this.isPersonalInvestor = false;
        this.photos = [];
        this.pendingProfileCounter = null;;
        this.hasActiveProfile = false;
        this.isSetRefCode = false;
        this.isZaloOtp = false;
        this.isKyc = false;
        this.isFirst = false;
        this.additionalDocuments = [];
        this.avatar  = '';
        this.country = null;
        this.province = null;;
        this.authorizedPerson = '';
        this.bankAccounts = [];
        this.bankAccount = null;
        this.balance = '';
        this.gender = null;
        this.birthDate = '';
        this.passportDateOfIssue = '';
        this.passportDateOfIssueModel = null;;
        this.passportPlaceOfIssue = '';
        this.businessCode = '';
        this.contactPerson = '';
        this.organizationType = '';
        this.taxCode = '';
        this.phone = '';
        this.phonePostal = '';
        this.phoneCountryCode = '';
        this.profilePicture = '';
        this.followingIssuers = [];
        this.followingBondIssuers = [];
        this.userSocials = [];
        this.website = '';
        this.referralCode = undefined;
        this.profileStep = 0;
        this.totalDayInUse = 0;
        this.totalValidRefUser = 0;
    }
    setProfile(profile) {
        this.userType = profile.userType || 'INVESTOR';
        this.idTypeId = (profile.idType || {}).id || profile.idTypeId;
        this.issuerType = profile.issuerType || {};
        this.secondContactInfo = profile.secondContactInfo || { country: {}};
        this.showPhoneForInvestor = profile.showPhoneForInvestor;
        this.photos = profile.photos || [];
        this.pendingProfileCounter= profile.pendingProfileCounter || {};
        this.photos.forEach((item) => {
            if (item.photoType === 'AVATAR') {
                this.avatar = item.url || "./assets/images/icon/upload.png"
            }
        })
        this.country = profile.country || {};
        this.province = profile.province || {};
        this.bankAccounts = (profile.bankAccounts || []).slice(0, 1) || {};
        this.gender = profile.gender || 0;
        this.datePassport = profile.datePassport || {};
        this.passportDateOfIssueModel = profile.passportDateOfIssueModel || {};
        this.phoneCountryCode  = profile.phoneCountryCode || 'VN';
        this.profilePicture = profile.profilePicture;
        if (profile.website && profile.website.substring(0, 4) !== 'http') {
            profile.website = 'http://' + profile.website || '';
        }
    }
}