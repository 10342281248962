<!-- Modal content-->
<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      <img [src]="iconImage" class="image-success" />
      <h4 class="modal-header-title-text">{{header || 'Thất bại'}}</h4>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-12 modal-body-message" [innerHTML]="message">
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end align-items-center">
      <button class="btn btn-ok" (click)="dismiss()">{{'Đóng' }}</button>
    </div>
  </div>
</div>