/**
 * declare Jquery
 * */
declare let $: any;

export class ShortcutService{
  listPage: any = [];
  static component: any;
  static tab: any;
  static isAddShortcutTab: boolean = false;
  static urls: any = [];
  static selectedUrl: any = 0;
  static router: any;
  static funcEsc: any;
  static isExecute:  boolean = false;
  constructor(
  ) {
  }
  
  static inputTab(component, tab) {
    this.component = component;
    this.tab = tab;
    this.addShortcutTab();
  }
  static inputTopMenu(urls, router) {
    this.urls = urls;
    this.router = router;
    this.addTopMenu();
  }
  static addTopMenu() {
    setTimeout(() => {
      var keys = {};
      $('body').keydown(function (e) {
        keys[e.which] = true;
      });
      $('body').keyup((e) => {
        if (keys[113]) {
          this.router.navigate([this.urls[0]]);
          keys[113] = false;
        }
      });
    }, 1000)
  }
  static addShortcutTab() {
    setTimeout(() => {
      var keys = {};
      $('body').keydown(function (e) {
        keys[e.which] = true;
      });
      $('body').keyup((e) => {
        if (keys[9]) {
          if (keys[49]) {
            this.component[this.tab].tabs[0].active = true;
          } else if (keys[50]) {
            this.component[this.tab].tabs[1].active = true;
          } else if (keys[51]) {
            this.component[this.tab].tabs[2].active = true;
          } else if (keys[52]) {
            this.component[this.tab].tabs[3].active = true;
          } else if (keys[53]) {
            this.component[this.tab].tabs[4].active = true;
          }
        }
        keys= {
          9: keys[9]
        };
      });
    }, 1000)
  }
  static addOneKey(key, func) {
    setTimeout(() => {
      this.funcEsc = func;
      var keys = {};
      $('body').keydown(function (e) {
        keys[e.which] = true;
      });
      $('body').keyup((e) => {
        if (keys[key]) {
          if (this.funcEsc && !this.isExecute) {
            this.isExecute = true;
            setTimeout(() => {
              this.isExecute = false;
            }, 1000)
            this.funcEsc();
          }
        }
        keys= {};
      });
    }, 100)
  }
  static removeFuncEsc() {
    this.funcEsc = undefined;
  }
  static offShortcutTab() {
    $('body').off('keydown');
    $('body').off('keyup');
  }
}
