import {AfterViewInit, Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from "@angular/core";
import { Constant } from "../../constant";
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtil } from 'src/app/common/utils/date.util';
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
    selector: 'success-popup',
    templateUrl: "./success-popup.html",
    styleUrls: ["./success-popup.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class SuccessPopupComponent implements OnInit {
    public Constant = Constant;
    @Output() result: EventEmitter<any> = new EventEmitter();
    @Input() header: any;
    @Input() message: any;
    @Input() isOkButton: any;
    @Input() iconImage: any = './assets/images/icons/success.png';
    @Input() isDismissAll: boolean;
    constructor(
        public eventManger: EventManagerService,
        public activeModal: NgbActiveModal,
        public ngModal: NgbModal
    ) {}
    ngOnInit() {
        console.log('open toast: ', DateUtil.parseDate(Date.now(), 'HH:mm:ss'));
    }
    dismiss() {
        if (!this.isDismissAll) {
            this.activeModal.dismiss();
        } else {
            this.ngModal.dismissAll();
        }
        this.result.emit(true);
    }
}
