<div class="dropdown" [ngClass]="disabled ? 'disabled' : ''" [style.background-color]="backgroundColor">
  <div class="form-control dropdown-display-value" id="action{{id}}" (click)="open()" [style.min-width]="minWidth">
    <span>{{name || title}}</span>
  </div>
  <div id="{{id}}" class="dropdown-content" [ngClass]="openDropdownlist ? 'show' : ''">
    <input class="form-control" type="text" placeholder="Search.." [(ngModel)]="textSearch" (keyup)="filter()">
    <div class="list-item" *ngIf="groups.length === 0; else haveGroups">
      <a class="d-flex" (click)="chooseValue(defaultItem)" *ngIf="!isNoAll">
        <span>Chọn tất cả</span>
      </a>
      <a class="record" *ngFor="let item of list" [ngClass]="item.isHide ? 'hide' : ''" (click)="chooseValue(item)">
        <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px;">
        <span>{{item[field]}}</span>
        <span *ngIf="item.isCheck">
          <svg height="15px" width="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 17.837 17.837" xml:space="preserve">
            <g>
              <path style="fill:#030104;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
                c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
                L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"/>
            </g>
          </svg>
        </span>
      </a>
    </div>
    <ng-template #haveGroups>
      <div class="list-item">
        <div class="d-flex flex-column" *ngFor="let group of groups">
          <span class="sub-title" (click)="chooseGroup(group)">{{group.option?.title}}</span>
          <div class="d-flex flex-column" style="margin-left: 15px;">
            <a class="record" *ngFor="let item of group.list" (click)="chooseValue(item)"
              [ngClass]="item.isHide ? 'hide' : ''">
              <img *ngIf="item.icon" [src]="item.icon" height="25" style="margin-right: 10px;">
              <span>{{item[field]}}</span>
              <span *ngIf="item.isCheck"><i class="fa fa-check" aria-hidden="true"></i></span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</div>
<span id="backdrop" class="backdrop"></span>