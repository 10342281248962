import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventManagerService } from '../../service/event-manager.service';
declare let $;
@Component({
  selector: 'dropdown',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss']
})
export class DropdownSearchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() list: any;
  @Input() disabled: any;
  @Input() isHaveContent = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Output() resultGroup: EventEmitter<any> = new EventEmitter();
  @Input() value: any = null;
  @Input() field: string = 'name';
  @Input() key: string = 'id';
  @Input() rowTemplate: TemplateRef<any>;
  @Input() name: any = '';
  @Input() multiple: any = false;
  @Input() backgroundColor = 'transparent';
  @Input() minWidth = '120px';
  @Input() values: any[] = [];
  @Input() optionGroups: OptionGroup[] = [];
  defaultItem: any = { id: undefined };
  @Input() isNoAll: any = true;
  textSearch: string;
  groups: any[] = [];
  openDropdownlist = false;
  eventSubscribers?: Subscription[] = [];
  id = 'myDropdown' + Math.floor(Math.random() * 1000) + Math.floor(Math.random() * 999);
  constructor(
    private eventManager: EventManagerService
  ) {

  }
  ngOnInit() {
    this.eventSubscribers = [
      this.eventManager.subscribe('selected-item', (res) => {
        if (res.content) {
          this.chooseValue(res.content);
        }
      }),
      this.eventManager.subscribe('body-click', (res) => {
        const event = res.content.event;
        if ($(event.target).closest('#' + this.id).length === 0 && $(event.target).closest('#action' + this.id).length === 0) {
          this.openDropdownlist = false;
          document.getElementById(this.id).classList.remove("show");
        } else {
          this.openDropdownlist = true;
        }
      }),
    ];
  }
  ngAfterViewInit() {
    $('select').click(function (e) {
      e.preventDefault();
    });
  }
  ngOnDestroy(): void {
    this.eventManager.destroys(this.eventSubscribers);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.list && changes.list.currentValue) {
      this.list = JSON.parse(JSON.stringify(changes.list.currentValue || []));
      if (this.value) {
        const find = this.list.find(item => item[this.key] === this.value || item[this.key] === this.value[this.key] || item[this.field] === this.value[this.field]) || {};
        this.name = find[this.field];
      } else {
        this.name = '';
      }
    }
    if (!this.value && this.value !== 0) {
      this.name = this.title;
    }
    if ((this.value || this.value === 0) && this.list && this.list.length > 0) {
      const find = this.list.find(item => item[this.key] === this.value || item[this.key] === this.value[this.key] || item[this.field] === this.value[this.field]) || {};
      this.name = find[this.field];
    }
    if (this.values.length > 0 && this.multiple && this.list && this.list.length > 0) {
      this.list.forEach((item) => {
        const isExist = this.values.find((value) => {
          return value[this.key] === item[this.key] || value === item[this.key]
        });
        item.isCheck = isExist ? true : false;
      });
      const removeItems = [];
      const values = [];
      this.values.forEach((value) => {
        const index = this.list.findIndex((item) => {
          return value[this.key] === item[this.key] || value === item[this.key];
        });
        if (index === -1) {
          removeItems.push(index);
        } else {
          values.push(this.list[index]);
        }
      });
      removeItems.reverse();
      removeItems.forEach((index) => { this.values.splice(index, 1) });
      this.values = values;
      this.name = this.values.slice(0, 3).map(item => item[this.field]).join(', ') + `${this.values.length > 3 ? '...' : ''}`;
    }
    if (this.optionGroups.length > 0) {
      this.groups = [];
      this.optionGroups.forEach((option) => {
        const list = this.list.filter(item => option.values.some(value => value === item[option.field])) || [];
        this.groups.push({
          option,
          list
        })
      })
    }
  }
  open() {
    document.getElementById(this.id).classList.toggle("show");
    // document.getElementById('#backdrop').classList.toggle("show");
    $('#backdrop').toggleClass("show-backdrop")
    $("#backdrop").click(function (e) {
      $("#backdrop").removeClass("show-backdrop");
    });
  }
  filter() {
    const filter = this.textSearch.toUpperCase();
    if (!this.groups.length) {
      this.list.forEach((item) => {
        const text = (item[this.field] || '').toUpperCase();
        if (text.includes(filter) || !filter) {
          item.isHide = false;
        } else {
          item.isHide = true;
        }
      })
    } else {
      this.groups.forEach((group) => {
        group.list.forEach((item) => {
          const text = (item[this.field] || '').toUpperCase();
          if (text.includes(filter) || !filter) {
            item.isHide = false;
          } else {
            item.isHide = true;
          }
        })
      })
    }
  }
  chooseValue(value) {
    if (!this.multiple) {
      document.getElementById(this.id).classList.remove("show");
      this.value = value || {};
      this.name = this.value[this.field];
      this.result.emit(value);
    } else {
      const firstValue = this.values[0];
      if (firstValue && !firstValue.id) {
        this.values = this.list.filter((item) => item.isCheck);
      }
      const index = this.values.findIndex(item => item[this.key] === value[this.key]);
      if (index === -1) {
        value.isCheck = true;
        if (!this.values.length && !value[this.key]) {
          this.values = [value];
          this.list.forEach((item) => item.isCheck = true);
        } else if (this.values.length && !value[this.key]) {
          this.values = [];
          this.list.forEach((item) => item.isCheck = false);
        } else if (value[this.key]) {
          this.values.push(value);
        }
      } else {
        value.isCheck = false;
        this.values.splice(index, 1);
      }
      this.name = this.values.slice(0, 3).map(item => item[this.field]).join(', ') + `${this.values.length > 3 ? '...' : ''}`;
      this.result.emit(this.values);
    }
    this.name = this.name || '';
    this.name = this.name.substring(0, 20) + (this.name.length > 20 ? '...' : '');
    $("#backdrop").removeClass("show-backdrop");

  }
  chooseGroup(group) {
    this.resultGroup.emit(group);
  }
}
export class OptionGroup {
  field: string;
  values: any[];
  title: string;
  constructor(params?) {
    if (!params) { return };
    this.field = params.field;
    this.values = params.values;
    this.title = params.title;
  }
}
