<div class="d-flex flex-column">
  <textarea 
    name="" #autoResizeTextarea placeholder="{{placeholder}}"
    rows="{{rows}}" cols="{{cols}}" style="min-height: 100px;"
    [(ngModel)]="value" (ngModelChange)="changeValue()" (keydown)="catchKeydown($event)"
    class="form-control input-note"
  ></textarea>
  <div class="text-right w-100" *ngIf="maxLength">
    <span>Ký tự: {{value?.length || 0}}/{{maxLength}}</span>
  </div>
</div>
