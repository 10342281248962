<div class="modal-contents">

  <!-- Modal Header -->
  <div class="modal-header">
    <div class="modal-header-title">
      <img [src]="iconImage" class="image-success" />
      <h4 class="modal-header-title-text">{{header || title || 'Xác nhận'}}</h4>
    </div>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="col-12 modal-body-message" [innerHTML]="message">
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end align-items-center">
      <a class="btn btn-close mr-4" (click)="cancel()">{{buttonCancelLabel}}</a>
      <button class="btn btn-ok" (click)="argree()">
        <span> {{buttonLabel}}</span>
      </button>
    </div>
  </div>
</div>