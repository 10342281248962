import {Component, OnDestroy, OnInit, Input, ViewEncapsulation} from '@angular/core';
import { EventManagerService } from '../../service/event-manager.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'pick-color',
  templateUrl: './pick-color.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pick-color.component.scss']
})
export class PickColor implements OnInit {
  @Input() value;
  @Input() broadcast;
  public arrayColors: any = {
    color1: '#2883e9',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  constructor(
    public eventManager: EventManagerService,
    public activeModal: NgbActiveModal,
  ) {
  }
  ngOnInit() {
    console.log(this.value);
  }
  onChangeColor(color: string) {
    this.value = color;
    const broadcast = this.broadcast || 'change-color';
    this.eventManager.broadcast({
      name: broadcast,
      content: {
        color
      }
    })
  }
  dismiss() {
    this.activeModal.dismiss();
  }
}
