import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject, debounceTime } from "rxjs";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "input-note",
  styleUrls: ["./input-note.component.scss"],
  templateUrl: "./input-note.component.html",
})
export class InputNoteComponent implements OnInit, AfterViewInit
{
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() maxLength: any;
  @Input() rows: any;
  @Input() cols: any;
  @Input() placeholder: any;
  @Input() isValidated: boolean = false;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @ViewChild("autoResizeTextarea") textarea: ElementRef;
  callApi$ = new Subject();
  beforeKeydown: any;
  constructor(
  ) {
  }

  ngOnInit() {
    this.callApi$.pipe(
      debounceTime(200),
      ).subscribe((event)=> { return this.emitData();});
    this.value = this.value || '';
  }
  ngAfterViewInit(): void {
    this.autoResize();
  }
  updateForm(): void {
  }
  emitData() {
    this.valueChange.emit(this.value);
  }
  changeValue() {
    if ( (this.value || '').length > this.maxLength ) {
      setTimeout(() => {
        this.value = (this.value || '').substring(0, 500);
      }, 10)
    }
    this.callApi$.next(true);
  }
  onInput() {
    this.autoResize();
  }
  autoResize() {
    const textareaElement = this.textarea.nativeElement;
    textareaElement.style.height = "auto";
    textareaElement.style.height = `${textareaElement.scrollHeight}px`;
  }
  catchKeydown(event) {
    const key = event.key;

    if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab') {
      return;
   }

   if (key === 'Control') {
       this.beforeKeydown = key;
   }

   if ((key === 'v' && this.beforeKeydown === 'Control'  )) {
       this.beforeKeydown = '';
       return;
   }

   if ((key === 'c' && this.beforeKeydown === 'Control'  )) {
       this.beforeKeydown = '';
       return;
   }
   if ((key === 'a' && this.beforeKeydown === 'Control'  )) {
    this.beforeKeydown = '';
    return;
  }

  if ( (this.value || '').length === this.maxLength ) {
      event.preventDefault();
      return;
    }
  }
}
